/* Global RTL - LTR Fixes */
body[dir='rtl'] #country-menu .dial-code {
  direction: ltr !important;
}
body[dir='rtl'] #country-menu .country-name {
  padding-left: 6px !important;
}

body[dir='ltr'] #country-menu .country-name {
  padding-right: 6px !important;
}
body #country-menu .country-name {
  padding-right: 6px !important;
}
body[dir='rtl'] .MuiAutocomplete-endAdornment {
  right: auto !important;
  left: 0 !important;
}
body[dir='ltr'] .MuiAutocomplete-endAdornment {
  right: 0 !important;
  left: auto !important;
}

body[dir='rtl'] .MuiSelect-select.MuiSelect-select {
  padding-right: 0 !important;
  padding-left: 24px !important;
}
body[dir='ltr'] .MuiSelect-select.MuiSelect-select {
  padding-right: 24px !important;
  padding-left: 0 !important;
}

body[dir='rtl'] .MuiSelect-icon,
body[dir='rtl'] .MuiNativeSelect-icon {
  right: auto !important;
  left: 0 !important;
}
body[dir='ltr'] .MuiSelect-icon,
body[dir='ltr'] .MuiNativeSelect-icon {
  right: 0 !important;
  left: auto !important;
}
